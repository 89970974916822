<template>
  <div>
    <div v-show="visible" class="info">
      <top-bar :title="!submitData.id?'新增上访':edit?'修改上访':'查看上访'" :left="true"></top-bar>
      <!-- 上访地点 -->
      <van-popup v-model="armyShow" position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.armyList" value-key="label"
                    @confirm="armyConfirm" @cancel="armyShow = false"/>
      </van-popup>
      <!-- 建筑类别 -->
      <van-popup v-model="levelShow" position="bottom" >
        <van-picker title="请选择" show-toolbar :columns="selectData.levelList" value-key="label"
                    @confirm="levelConfirm"  @cancel="levelShow = false"/>
      </van-popup>
      <!-- 选择信访级别 -->
      <van-popup v-model="typeShow" position="bottom" >
        <van-picker title="请选择" show-toolbar :columns="selectData.typeList"
                    value-key="label"  @cancel="typeShow = false" @confirm="typeConfirm"/>
      </van-popup>
      <!-- 上访时间 -->
      <van-popup v-model="petitionDateShow" position="bottom" >
        <van-datetime-picker v-model="selectMsg.petitionDate" type="date" title="请选择"
                             @cancel="petitionDateShow = false" @confirm="timeConfirm"/>
      </van-popup>

      <van-cell-group>
        <van-cell class="info-title">
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">上访信息</span>
          </template>
        </van-cell>
        <van-cell title="上访人">
          <van-field clearable clear-trigger="always" v-model="submitData.userName" placeholder="请输入" readonly/>
        </van-cell>
        <van-cell title="上访级别" :value="selectMsg.levelStr" @click="levelShow = edit" :clickable="edit"
                  :value-class="{'value-common':selectMsg.levelStr=='请选择'}" :is-link="edit" />
        <van-cell title="信访级别" :value="selectMsg.typeStr" @click="typeShow = edit" :clickable="edit"
                  :value-class="{'value-common':selectMsg.typeStr=='请选择'}" :is-link="edit" />
        <van-cell title="是否涉军" :value="selectMsg.armyStr" @click="armyShow = edit" :clickable="edit"
                  :value-class="{'value-common':selectMsg.armyStr=='请选择'}" :is-link="edit" />
        <van-cell title="上访日期" :value="submitData.petitionDate" @click="petitionDateShow = edit"
                  :value-class="{'value-common':submitData.petitionDate=='请选择'}" :is-link="edit" />
        <!-- 目前状况 -->
        <van-cell title="目前状况">
            <van-field clearable clear-trigger="always" placeholder="请输入" v-model="submitData.currentSituation"></van-field>
        </van-cell>
        <!-- 工作措施 -->
        <van-cell title="工作措施">
            <van-field clearable clear-trigger="always" placeholder="请输入" v-model="submitData.workMeasures"></van-field>
        </van-cell>
        
        <van-cell title="包案领导姓名" >
            <van-field clearable clear-trigger="always" placeholder="请输入" v-model="submitData.leaderName"></van-field>
        </van-cell>
        <van-cell title="包案领导联系方式">
          <van-field clearable clear-trigger="always" placeholder="请输入"   v-model="submitData.leaderPhone"></van-field>
        </van-cell>
        <van-cell title="专班人员姓名" >
            <van-field clearable clear-trigger="always" placeholder="请输入" v-model="submitData.className"></van-field>
        </van-cell>
        <van-cell title="专班人员联系方式" > 
            <van-field clearable clear-trigger="always" placeholder="请输入" v-model="submitData.classPhone"></van-field>
        </van-cell>     
        
        <van-field  clearable clear-trigger="always" v-model="submitData.reason" rows="4" class="left info-title" :border="false" autosize type="textarea" placeholder="上访事由" :readonly="!edit"/>
      </van-cell-group>

      <van-row class="btns">
        <van-col :span="24" v-if="edit">
          <van-button type="info" size="large" :loading="loading" round @click="submit">完成</van-button>
        </van-col>
        <van-row gutter="10" v-else>
          <van-col :span="12">
            <van-button plain type="danger" size="large" round @click="deleteHandle" style="background-color:transparent">删除</van-button>
          </van-col>
          <van-col :span="12">
            <van-button type="info" size="large" round @click="edit=true">编辑</van-button>
          </van-col>
        </van-row>
      </van-row>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {beforeFileUpload, formatterDate} from "@/utils/utils"
import {getVirtualDict} from '@/utils/common'
import placeList from "../visit/placeList"

export default {
  data() {
    return {
      i: '',
      visible: true,
      edit: false,
      file: {},
      detailData: {},
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      loading: false,
      placeListVisible: false,
      armyShow: false,
      levelShow: false,
      typeShow: false,
      petitionDateShow: false,
      isTasks: false,
      selectMsg: {
        armyStr: '请选择',
        levelStr: '请选择',
        typeStr: '请选择',
        currentSituationStr:'请选择',
        workMeasuresStr:'请选择',
        inspectionTypeStr:'请选择',
        petitionDate: new Date()
      },
      submitData: {
        id: '',
        army: '',
        addrId: '',
        level: '',
         mobile:'',
        rentHouseCount: 0,
        typeId: '',
        subarea: '',
        userName: '',
        userId: '',
        detailedAddressValue: [],
        inspectionType: '',
        // 目前状况
        currentSituation:'',
        // 工作措施
        workMeasures:'',
        petitionDate: '',
        // 现住址
        nowAddress:'',
        // 包案领导姓名
        leaderName:'',
        // 包案领导联系方式
        leaderPhone:'',
        // 专班人员姓名
        className:'',
        // 专班人员联系方式
        classPhone:'',
        reason: '',
        type: '',

      },
      selectData: {
        armyList: [{label: "是", value: "1"}, {label: "否", value: "0"}],
        levelList: [],
        typeList: [{value:"1",label:"个人访"},{value:"2",label:"集体访"}],
      }
    }
  },
  components: {
    topBar,
    placeList
  },
  methods: {
    // 初始化
    init() {
      getVirtualDict('petitionLevel', (dict) => {
        this.selectData.levelList = dict
      })
      if (this.submitData.id) {
        this.edit = false
        this.getInfo()
      } else {
        this.edit = true
      }
    },
    // 获取详情
    getInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/petition/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.submitData.id
        })
      }).then(({data}) => {
        console.log(data);
        if (data && data.code === 0) {
          this.submitData.userName = data.petition.userName
          this.submitData.userId = data.petition.userId
          this.submitData.army = data.petition.army
          this.submitData.level = data.petition.level
          this.submitData.type = data.petition.type
          this.submitData.petitionDate = data.petition.petitionDate
          // 现住址
          this.submitData.nowAddress = data.petition.nowAddress
          this.submitData.leaderName = data.petition.leaderName
          this.submitData.leaderPhone = data.petition.leaderPhone
          this.submitData.className = data.petition.className
          this.submitData.classPhone = data.petition.classPhone
          this.submitData.reason = data.petition.reason
          this.selectMsg.typeStr = data.petition.typeStr
          this.selectMsg.levelStr = data.petition.levelStr
          // 目前状况
          this.submitData.currentSituation = data.petition.currentSituation
          this.submitData.workMeasures = data.petition.workMeasures
          this.selectMsg.armyStr = data.petition.army == 1 ? '是' : '否'
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    deleteHandle() {
      this.$dialog.confirm({
        title: '确定删除吗？'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/petition/delete'),
          method: 'post',
          params: this.$http.adornParams({
            id: parseInt(this.submitData.id)
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$toast.success({
              message: '删除成功',
              duration: 3000,
              onOpened: () => {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // 提交
    submit() {
      if (this.submitData.address === '') {
        return this.$toast.fail('失败:上访人不能为空');
      }
      if (this.submitData.level === '') {
        return this.$toast.fail('失败:请选择上访级别');
      }
      if (this.submitData.type === '') {
        return this.$toast.fail('失败:请选择信访类别');
      }
      if (this.submitData.army === '') {
        return this.$toast.fail('失败:请选择是否涉军');
      }
      if (this.submitData.petitionDate === '') {
        return this.$toast.fail('失败:上访时间不能为空');
      }
      if (this.submitData.reason === '') {
        return this.$toast.fail('失败:上访描述不能为空');
      }
      this.loading = true
      this.$http({
        url: this.$http.adornUrl(`/wxapp/petition/${this.submitData.id ? 'update' : 'save'}`),
        method: 'post',
        data: {
          army: this.submitData.army,
          community: parseInt(this.submitData.orgId),
          id: parseInt(this.submitData.id),
          level: this.submitData.level,
          levelStr: this.selectMsg.levelStr,
          petitionDate: this.submitData.petitionDate,
          reason: this.submitData.reason,
          times: 1,
          type: this.submitData.type,
          typeStr: this.selectMsg.typeStr,
          userId: parseInt(this.submitData.userId),
          userName: this.submitData.userName,
          // 现住址
          nowAddress:this.submitData.nowAddress,
          leaderName:this.submitData.leaderName,
          leaderPhone:this.submitData.leaderPhone, 
          className:this.submitData.className,
          classPhone:this.submitData.classPhone,
         
          // 目前状况
          currentSituation: this.submitData.currentSituation,
          workMeasures:this.submitData.workMeasures
        }
      }).then(({data}) => {
        if (data.code == 0) {
          this.$toast.success({
            message: `${this.submitData.id ? '修改成功' : '新增成功'}`,
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.loading = false
          this.$toast.fail(data.msg);
        }
      })
      this.loading = false
    },
    // 是否涉军选择
    armyConfirm(value) {
      console.log(value);
      if (value) {
        this.selectMsg.armyStr = value.label
        this.submitData.army = value.value
      }
      this.armyShow = false
    },
    // 上访级别选择
    levelConfirm(value) {
       console.log(value);
      if (value) {
        this.selectMsg.levelStr = value.label
        this.submitData.level = value.value
      }
      this.levelShow = false
    },
    // 信访级别选择
    typeConfirm (value) {
      if (value) {
        this.selectMsg.typeStr = value.label
        this.submitData.type = value.value
      }
      this.typeShow = false
    },
    // 上访类型选择
    inspectionTypeFinish(value) {
      if (value) {
        this.selectMsg.inspectionTypeStr = value.selectedOptions[0].label
        this.submitData.inspectionType = value.value
      }
      this.inspectionTypeShow = false
    },
    // 日期选项格式化
    formatDate(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    timeConfirm(value) {
      if (value) {
        this.selectMsg.petitionDate = formatterDate(value)
        this.submitData.petitionDate = formatterDate(value)
      }
      this.petitionDateShow = false
    },
  },
  created() {
    debugger
    this.submitData.id = this.$route.query.id || ''
    this.submitData.userId = this.$route.query.userId || ''
    this.submitData.userName = this.$route.query.userName || ''
    this.submitData.petitionDate = formatterDate(new Date())
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId
    this.init()
  },
  beforeDestroy() {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
<style scoped>
.left >>> .van-field__control {
  text-align: left !important;
}
.left >>> .van-icon {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
